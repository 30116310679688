import React from "react"
import { graphql, Link } from "gatsby"
import Nav from "../components/nav"
import Footer from "../components/footer"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import BtnPrimary from "../components/buttons/primary"
import BtnPrimaryTwo from "../components/buttons/primary-two"
import BtnSecondary from "../components/buttons/secondary"
import icon1 from "../images/icons/icon_10.png"
import icon2 from "../images/icons/icon_9.png"
import icon3 from "../images/icons/icon_8.png"
import icon4 from "../images/icons/icon_7.png"
import icon5 from "../images/icons/icon_11.png"
import icon6 from "../images/icons/icon_6.png"

const ixIx3 = ({ data, location }) => {
  const params = new URLSearchParams(location.search);
  var url_param = location.search;
  var url_param2;

  var utm_campaign = params.get("utm_campaign");
  var utm_source = params.get("utm_source");
  var utm_medium = params.get("utm_medium");
  var utm_content = params.get("utm_content");

  if(url_param){
    url_param = url_param.replace(/[^\w\s&=?]/gi, '').replace(/\s/g, '');
    url_param2 = url_param.replace(/\?/g, '&');
  }

  console.log(url_param2)

  if(utm_campaign) {
    utm_campaign = utm_campaign.replace(/[^\w\s]/gi, '').replace(/\s/g, '');
  }
  if(utm_source) {
    utm_source = utm_source.replace(/[^\w\s]/gi, '').replace(/\s/g, '');
  }
  if(utm_medium) {
    utm_medium = utm_medium.replace(/[^\w\s]/gi, '').replace(/\s/g, '');
  }
  if(utm_content) {
    utm_content = utm_content.replace(/[^\w\s]/gi, '').replace(/\s/g, '');
  }
  let ix_action = (event) => {
      var page = document.getElementById('ix-ix3-page-1');
      page.classList.remove("d-none");
      var page2 = document.getElementById('ix-ix3-page-2');
      page2.classList.add("d-none");
      document.getElementById("ix-btn").classList.add("active");
      document.getElementById("ix3-btn").classList.remove("active");
    };

  let ix3_action = () => {
      var page = document.getElementById('ix-ix3-page-1');
      page.classList.add("d-none");
      var page2 = document.getElementById('ix-ix3-page-2');
      page2.classList.remove("d-none");
      document.getElementById("ix-btn").classList.remove("active");
      document.getElementById("ix3-btn").classList.add("active");
  };

return (
  <Layout>
    <Nav urlParam={url_param}/>
    <div className="masthead relative">
      <div className="masthead hidden lg:block">
        <GatsbyImage
          image={getImage(
            data.allMdx.edges[0].node.frontmatter.masthead_desktop
          )}
          alt={data.allMdx.edges[0].node.frontmatter.title}
        />
      </div>
      <div className="masthead block lg:hidden">
        <GatsbyImage
          image={getImage(
            data.allMdx.edges[0].node.frontmatter.masthead_mobile
          )}
          alt={data.allMdx.edges[0].node.frontmatter.title}
        />
      </div>
      <div className="relative md:absolute text-black md:text-white top-0 left-0 w-full h-full">
        <div className="container py-5 md:mt-36">
          <div className="d-flex align-items-start">
            <img class="title-logo" src={icon5}/>
            <h1 className="font-light text-2xl md:text-4xl xl:text-5xl">
                {data.allMdx.edges[0].node.frontmatter.masthead_line1 !== ""}
                <span className="inline md:block">
                  {data.allMdx.edges[0].node.frontmatter.masthead_line1}
                  {data.allMdx.edges[0].node.frontmatter.masthead_line2 !==
                    "" && <span className="inline">&nbsp;</span>}
                </span>
                {data.allMdx.edges[0].node.frontmatter.masthead_line2 !== "" && (
                  <span className="inline md:block md:mt-1">
                    {data.allMdx.edges[0].node.frontmatter.masthead_line2}
                  </span>
                )}
              </h1>
          </div>
        </div>
      </div>
    </div>
    <section className="section ix-ix3-section">
      <div className="container py-10 mb-10">
          <h1 className="text-xl md:text-3xl xl:text-4xl">ENJOY 10-YEAR COMPLIMENTARY CHARGING* WITH<br/>THE BMW iX OR BMW iX3.</h1>
          <span class="border-t border-neutral-400 block w-full my-3"></span>
          <p className="mt-10">Plug into the joy of electric driving with the BMW iX and BMW iX3. The BMW iX3 comes with the M Sport package as standard, optimising its sporty aura and driving pleasure combined with a range output of 461 km for journeys short and long.</p>
          <p>Also pioneering a new era, the first-ever BMW iX redefines the concept of a sports activity vehicle while boasting outstanding driving pleasure with ultra-modern connectivity.</p>
          <p>With either choice, you get maximum driving pleasure with zero emissions and what&#39;s more, enjoy 10-year complimentary charging on us. Visit our showroom today and drive the difference with Eurokars Auto.</p>
          <div className="flex flex-col md:flex-row space-x-0 md:space-x-3 my-8">
            <Link
              to={"/register/" + url_param}
              data-gtm-category="BMW I7"
              data-gtm-action="Clicked_Btn"
              data-gtm-label="Register your interest"
            >
              <BtnPrimaryTwo cta="Register your interest" />
            </Link>
          </div>

          
      <div className="row d-none d-md-flex">
            <div className="col-8" id="ix-ix3-page-1">
                <div className="ix-ix3-banner-left ml-10">
                    <div className="mx-40 pt-10 pb-40">
                        <h2 className="mb-10">THE PATHFINDING NEW BMW iX.</h2>
                        <ul className="list-dot pl-4">
                            <li>Designed as a luxurious living space on wheels</li>
                            <li>Up to 425 km electric range (WLTP)</li>
                            <li>Recharge up to 95 km range in just 10 minutes</li>
                        </ul>
                    </div>
                    <div className="black-bg"></div>
                        <GatsbyImage
                          image={getImage(
                            data.allMdx.edges[0].node.frontmatter.ix_image
                          )}
                          className="ix_image"
                          alt={data.allMdx.edges[0].node.frontmatter.title}
                        />
                        <GatsbyImage
                          image={getImage(
                            data.allMdx.edges[0].node.frontmatter.ix3_image
                          )}
                          className="ix3_image"
                          alt={data.allMdx.edges[0].node.frontmatter.title}
                        />
                </div>
            </div>
            <div className="col-8 d-none" id="ix-ix3-page-2">
                <div className="ix-ix3-banner-left ml-10">
                    <div className="mx-40 pt-10 pb-40">
                        <h2 className="mb-10"> BMW iX3. ADVENTURE, ELECTRIFIED.</h2>
                        <ul className="list-dot pl-4">
                            <li>Sustainable and smart connected electromobility</li>
                            <li>Classy and sleek design with strong road presence</li>
                            <li>Up to 461 km driving range (WLTP)</li>
                        </ul>
                    </div>
                        <GatsbyImage
                          image={getImage(
                            data.allMdx.edges[0].node.frontmatter.ix3_image
                          )}
                          className="ix3_image_2"
                          alt={data.allMdx.edges[0].node.frontmatter.title}
                        />
                </div>
            </div>
            <div className="col-4">
                <div className="pl-10 pt-12p">
                    <button id="ix-btn" className="text-btn d-block active" onClick={ix_action}>BMW iX xDrive40</button>
                    <button id="ix3-btn" className="text-btn d-block" onClick={ix3_action}>BMW iX3 M Sport Impressive</button>
                </div>
            </div>
      </div>
          
      <div className="d-block d-md-none mobile-ixix3-banner">
          <div className="container">
            <h2 className="mb-10">THE PATHFINDING NEW BMW iX.</h2>
            <ul className="list-dot pl-4">
                <li>Designed as a luxurious living space on wheels</li>
                <li>Up to 425 km electric range (WLTP)</li>
                <li>Recharge up to 95 km range in just 10 minutes</li>
            </ul>
          </div>
          <GatsbyImage
              image={getImage(
                data.allMdx.edges[0].node.frontmatter.ix_image
              )}
              className="ix_image_mb"
              alt={data.allMdx.edges[0].node.frontmatter.title}
            />
        </div>

        <div className="d-block d-md-none mobile-ixix3-banner2">
          <div className="container">
              <h2 className="mb-10"> BMW iX3. Adventure, electrified.</h2>
              <ul className="list-dot pl-4">
                  <li>Sustainable and smart connected electromobility</li>
                  <li>Classy and sleek design with strong road presence</li>
                  <li>Up to 461 km driving range (WLTP)</li>
                  <li>Energy consumption from 18.5 kWh/100 km</li>
              </ul>
          </div>
          <GatsbyImage
              image={getImage(
                data.allMdx.edges[0].node.frontmatter.ix3_image
              )}
              className="ix_image_mb2"
              alt={data.allMdx.edges[0].node.frontmatter.title}
            />
        </div>

      <div className="container">
      <div className="mt-115px">
            <p className="font-bold">Experience sustainable mobility with the BMW iX and BMW iX3 at Eurokars Auto today.</p>
            <div className="mt-10">
              <Link
                to={"/test-drive/" + url_param}
                data-gtm-category="BMW I7"
                data-gtm-action="Clicked_Btn"
                data-gtm-label="Book a test drive"
              >
                <BtnPrimaryTwo cta="Book a test drive" />
              </Link>
            </div>
            <p className="mt-20">In addition, enjoy these benefits with your BMW Electric Vehicle:</p>
              <ul>
                      <li className="mt-3 d-flex align-items-center"><span><img class="w-50px" src={icon1}/></span>Complimentary home evaluation for EV charger installation</li>
                      <li className="d-flex align-items-center"><span><img class="w-50px" src={icon2}/></span>6 years&#39; BMW Service Inclusive without limitation on mileage</li>
                      <li className="d-flex align-items-center"><span><img class="w-50px" src={icon3}/></span>5 years&#39; or 200,000 km BMW Extended Warranty</li>
                      <li className="d-flex align-items-center"><span><img class="w-50px" src={icon4}/></span>8 years&#39; or 160,000 km warranty for the High-Voltage Battery System</li>
                      <li className="d-flex align-items-center"><span><img class="w-50px" src={icon6}/></span>Option to add on either:</li>
                  <ul className="pl-10 ml-6 list-dot">
                      <li>BMW Home Charging Package – includes a wall-mounted AC Charger and complimentary installation</li>
                      <li>BMW Public Charging Package – includes a 3-year 10,000 kWh subscription with Shell Recharge with no monthly cap</li>
                  </ul>
              </ul>
              <p className="text-terms mt-20">T&Cs: * Based on average of 20,000 km travelled per year, and on range offered by each model (iX and iX3). The cost per charge is based on existing public charging rates in the market • Offers are on a first-come, first-served basis while stocks last • Effective offers are subject to in-house trade in, loan and insurance conditions • Car specifications may vary • This page provides information on BMW products and does not constitute an offer of sale • Any sale is subject to our applicable terms and conditions.</p>
        </div>
      </div>
      </div>
    </section>
    <Footer urlParam={url_param} />
  </Layout>
)
}
export const query = graphql`
query {
  allMdx(filter: { frontmatter: { page_id: { eq: "ix-ix3" } } }) {
    edges {
      node {
        frontmatter {
          slug
          page_id
          date
          title
          description
          masthead_line1
          masthead_line2
          masthead_desktop {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                blurredOptions: { width: 300 }
                placeholder: BLURRED
                breakpoints: [1080, 1366, 1920]
                outputPixelDensities: [1, 1.5, 2]
              )
            }
          }
          masthead_mobile {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                blurredOptions: { width: 150 }
                placeholder: BLURRED
                breakpoints: [650]
                outputPixelDensities: [2]
              )
            }
          }
          ix_image {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                blurredOptions: { width: 150 }
                placeholder: BLURRED
                breakpoints: [650]
                outputPixelDensities: [2]
              )
            }
          }
          ix3_image {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                blurredOptions: { width: 150 }
                placeholder: BLURRED
                breakpoints: [650]
                outputPixelDensities: [2]
              )
            }
          }
        }
      }
    }
  }
}
`

export default ixIx3

const seoDetails = {
title: "Eurokars Auto | iX and iX3",
description: "Born from a vision. Created for electric mobility.",
keywords: "iX,iX3",
image: "og-eka-contact-us.jpg",
}

export const Head = () => (
<>
  <title>{seoDetails.title}</title>
  <meta property="og:type" content="website" />
  <meta
    name="description"
    property="description"
    content={seoDetails.description}
  ></meta>
  <meta
    name="og:description"
    property="og:description"
    content={seoDetails.description}
  ></meta>
  <meta
    name="keywords"
    property="keywords"
    content={seoDetails.keywords}
  ></meta>
  <meta
    name="og:url"
    property="og:url"
    content={`${process.env.GATSBY_BASE_URL}`}
  ></meta>
  <meta
    name="og:image"
    property="og:image"
    content={`${process.env.GATSBY_BASE_URL}/img/${seoDetails.image}`}
  ></meta>
  <script
    async
    src="https://www.googletagmanager.com/gtag/js?id=AW-782099393"
  ></script>
</>
)

